import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthorizationInterceptor } from './interceptor/auth.interceptor';

@NgModule({
    providers: [
      provideHttpClient(withInterceptorsFromDi()),
      { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true }
    ]
})

export class CoreModule { }
